import React, { useRef, useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"

import { usePrismBlogs } from '../hooks/use_prism-blogs'

import GetBodyItem from '../components/_helpers/_get_body_item'
import ArrayHelper from "../components/_helpers/_array_helper"
import Layout from "../components/layout"
import Head from '../components/head'

import { BlogItemTypes } from "../components/_blog/_blog_item"
import { ButtonSquare } from "../components/buttons/_button_square"
import BlogCarousel from '../content/blog/blog_carousel'
import BlogBlock from "../components/_blog/_blog_block"
import BlogSearch from "../content/blog/BlogSearch"

import "../styles/pages/blog/blog.scss"
// import BlogCarouselNextButton from "../content/blog/BlogCarouselNextButton"
import {useWindowSize} from '../hooks/use_window-size'

const BlogPage = () => {

  const query = useStaticQuery(graphql`
    {
      allPrismicBlogpage{
        edges{
          node{
            ... on Node{
              __typename
              ... on PrismicBlogpage{
                data{
                  title{
                    html
                  }
                  body{
                    __typename
                    ... on PrismicBlogpageBodyMetas {
                      primary{
                        title{
                          text
                        }
                        description{
                          text
                        }
                        image_share{
                          url
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }    
  `)

  const prismicBlogs = usePrismBlogs()
  const prismicMetas = query.allPrismicBlogpage
  const item = prismicMetas.edges[0]
  const metasData = new GetBodyItem().get_item(item.node, "PrismicBlogpageBodyMetas")
  const windowSize = useWindowSize();
  const [chunkType, setChunkType] = useState(windowSize.width < 1280 ? 'tow_columns' : 'three_columns')

  const metas = {
    title: metasData.primary.title.text,
    description: metasData.primary.description.text,
    image: metasData.primary.image_share.url
  }

  const blog_total = prismicBlogs.edges.length
  let chunks = ArrayHelper.chunk(prismicBlogs.edges, 6);
  let totalChunks = chunks.length;

  const blog_body = useRef(null)
  const [currentChunk, setCurrentChunk] = useState(0)
  const [append, setAppend] = useState(false)
  const [blocks, setBlocks] = useState([])

  const [has_more, setHasMore] = useState(blog_total > 6)

  useEffect(() => {
    if (append) {
      const blogList = [];
      const singleList = [];
      const tempList = chunks[currentChunk]
      const totalTemp = tempList.length
      if(chunkType == 'tow_columns'){
        for (let i = 0; i < totalTemp; i++) {
          let blogType = BlogItemTypes.TO_RIGHT
          let single_layout = ''
          if (i % 2 !== 0)
            blogType = BlogItemTypes.TO_LEFT
          if (totalTemp - 2 <= i && totalTemp > 2) {
            blogType = BlogItemTypes.SINGLE
            // if (i === 2) single_layout = 'left'
            // if (i === 5) single_layout = 'middle'
            // if (i === 3) single_layout = 'right'
          }
          if (blogType === BlogItemTypes.SINGLE) {
            singleList.push({ key: currentChunk + '_' + i, blog: tempList[i], blogType, single_layout });
          } else {
            blogList.push({ key: currentChunk + '_' + i, blog: tempList[i], blogType, single_layout });
          }
        }
      }else{
        for (let i = 0; i < totalTemp; i++) {
          let blogType = BlogItemTypes.TO_RIGHT
          let single_layout = ''
          if (i % 2 !== 0)
            blogType = BlogItemTypes.TO_LEFT
          if (totalTemp - 3 <= i && totalTemp > 3) {
            blogType = BlogItemTypes.SINGLE
            // if (i === 2) single_layout = 'left'
            // if (i === 5) single_layout = 'middle'
            // if (i === 3) single_layout = 'right'
          }
          if (blogType === BlogItemTypes.SINGLE) {
            singleList.push({ key: currentChunk + '_' + i, blog: tempList[i], blogType, single_layout });
          } else {
            blogList.push({ key: currentChunk + '_' + i, blog: tempList[i], blogType, single_layout });
          }
        }
      }

      blocks.push({ blogs: blogList, singles: singleList })

      setBlocks(blocks)
      setHasMore(totalChunks - 1 > currentChunk)
      setCurrentChunk(currentChunk + 1)
      setAppend(false)
    }
  }, [append])

  useEffect(() => {
    resteValues()
    setAppend(true)
  },[chunkType])

  useEffect(()=>{
    if(windowSize.width < 1280){
      if(chunkType == 'tow_columns') return
      setChunkType('tow_columns')
    }else{
      if(chunkType == 'three_columns') return
      setChunkType('three_columns')
    }
  }, windowSize)

  const resteValues = () => {
    setCurrentChunk(0)
    setBlocks([])
  }

  return (
    <Layout>
      <BlogSearch />
      <div className="blog-container">
        <div className="blog_header_stage">
          <div className="blog_carousel blog_home_carousel">
            <BlogCarousel datablogs={prismicBlogs}></BlogCarousel>
          </div>
          {/* <BlogCarouselNextButton /> */}
        </div>
        <div className="blog_body" ref={blog_body}>
          {
            blocks.map((item, key) => <BlogBlock key={`blog_block_${key}`} blocks={item} />)
          }
        </div>
        {
          has_more &&
          <div className="blog_footer">
            <ButtonSquare
              _bg_color="#001f5b"
              _text='Cargar más noticias'
              _onClick={() => setAppend(true)}
              _className={`blog_footer_button`}
            />
          </div>
        }
      </div>
      {/* <Social/> */}
    </Layout>
  )
}

export default BlogPage