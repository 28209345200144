import React, {useEffect, useRef, Fragment} from 'react'
import Loadable from "@loadable/component"
import { Picture } from 'react-responsive-picture';

import { ButtonSquare } from '../../components/buttons/_button_square';
import { BlogHeader } from '../../components/_blog/_blog_header';
import SliderArrow from '../../components/sliders/slider_arrow';

const AwesomeSwiper = Loadable(() => import("react-awesome-swiper"));

const BlogCarousel = ({datablogs}) =>{
    let sliderArrow = new SliderArrow();
    const bannerSectionRef = useRef(null)

    const config = {
        // width: 800,
        loop: true,
        autoplay: {
          delay: 300000,
          stopOnLastSlide: false,
          disableOnInteraction: true,
        },
        // Disable preloading of all images
        preloadImages: false,
        // Enable lazy loading
        lazy: true,
        speed: 500,
        navigation: {
          nextEl: '.banner__next',
          prevEl: '.banner__prev',
        },
        pagination: {
          el: '.swiper-pagination',
          bulletElement: 'li',
          hideOnClick: false,
          clickable: true,
        },
        on: {
          slideChange: function () {
            // console.log(this.activeIndex);
          },
        },
    };

    useEffect(()=>{
      if(!bannerSectionRef.current) return;
      sliderArrow.init(bannerSectionRef);
    },[])

    function create_dynamics_items(_items) {
      const lastItem = _items.edges.slice( _items.edges.length - 5)
        return lastItem.map((_item, _index) => {
          return (
            <div key={`carousel-${_item.node.uid}`} className="swiper-slide blog_carrousel_item">
              <BlogHeader blog={_item} _className="blog_carrousel_item_header" />
              <Picture
                sources = {[
                  {
                      srcSet: _item.node.data.header_image.xs.url,
                      media: "(max-width: 767px)",
                  },
                  {
                      srcSet: _item.node.data.header_image.sm.url,
                      media: "(max-width: 1023px)",
                  },
                  {
                      srcSet: _item.node.data.header_image.md.url,
                      media: "(max-width: 1280px)",
                  },
                  {
                      srcSet: _item.node.data.header_image.lg.url,
                      media: "(max-width: 1919px)",
                  },
                  {
                      srcSet: _item.node.data.header_image.url,
                      media: "(min-width: 1920px)",
                  },
                  ]}
              />
              <ButtonSquare
                _slug={_item.node.uid}
                _bg_color="#001f5b"
                _text='Leer Más'
                _to={`/blog/${_item.node.data.type.document[0].uid}/${_item.node.uid}`}
                _className={`blog_carrousel_item__show-more`}
              />
            </div>
          )
        });
    };

    return( datablogs !== undefined ?
      <div className="blog_carousel-container" ref={bannerSectionRef}>
        <div className={`banner__prev cursor__change`} ></div>
        <div className={`banner__next cursor__change`} ></div>
        <AwesomeSwiper config={config}>
          <div className="swiper-wrapper">
              {create_dynamics_items(datablogs)}
          </div>
          <div className="swiper-pagination"></div>
        </AwesomeSwiper>
      </div>
      : <></>

    )

}

export default BlogCarousel