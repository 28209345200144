import React from 'react'

import blogStyles from '../../styles/_components/_blog/_blog_block.module.scss'

import { BlogItem } from './_blog_item'

class BlogBlock extends React.Component {

    render() {
        if (this.props.blocks !== undefined) {
            return (
                <div className={`blog-block ${this.props._className}`}>
                    {
                        this.props.blocks.blogs.length > 0 &&
                        <div className={blogStyles.oBlogBlock__list}>
                            {
                                this.props.blocks.blogs.map((item, key) => {
                                    return <BlogItem key={`blog_item_${item.key}`} type={item.blogType} blog={item.blog} single_layout={item.layoutType}></BlogItem>
                                })
                            }
                        </div>
                    }
                    {
                        this.props.blocks.singles.length > 0 &&
                        <div className={blogStyles.oBlogBlock__singles}>
                            {
                                this.props.blocks.singles.map((item, key) => {
                                    return <BlogItem key={`blog_item_${item.key}`} type={item.blogType} blog={item.blog} single_layout={item.layoutType}></BlogItem>
                                })
                            }
                        </div>
                    }
                </div>
            )
        }
        else {
            return (<></>)
        }

    }
}

export default BlogBlock
