import React from 'react';
import Search from "../../components/svg/blog/Search" 
import styles from "../../styles/pages/blog/blog_search.scss"

function BlogSearch(props) {
  return (
    <div className={`blog_search_container`}>
      <Search _class="blog_serach_icon"/>
      <span className={`blog_search_pipe-icon`}>|</span>
    </div>
  );
}

export default BlogSearch;