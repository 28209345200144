import * as React from "react"

const Search = (props) => {
  return (
    <svg className={props._class} viewBox="0 0 13 13.5" {...props}>
      <path
        d="M12.8 12.3L9.6 8.9c.8-1 1.3-2.2 1.3-3.5 0-3-2.4-5.4-5.4-5.4S0 2.4 0 5.4s2.4 5.4 5.4 5.4c1.1 0 2.2-.3 3.1-1l3.2 3.4c.1.1.3.2.5.2s.4-.1.5-.2c.4-.2.4-.7.1-.9zM5.4 1.4c2.2 0 4 1.8 4 4s-1.8 4-4 4-4-1.8-4-4 1.8-4 4-4z"
        fill="#ff4056"
      />
    </svg>
  )
}

export default Search